<template>
    <div v-loading="$waiting.is('loading')">
        <el-table :default-sort="preSelectedSort" :data="clientsList" size="medium" @sort-change="sortChange" @current-change="handleCurrentChange">
            <el-table-column prop="clientNumber" label="Nummer" width="120" sortable="custom" />
            <el-table-column prop="clientName" label="Namn" sortable="custom" />
            <el-table-column prop="registrationNumber" label="Organisationsnummer" sortable="custom" />
            <el-table-column prop="companyTypeName" label="Typ" sortable="custom" />
            <el-table-column prop="companyStatusName" label="Status" sortable="custom" />
            <el-table-column prop="test" label="Testbolag" sortable="custom">
                <template slot-scope="scope">
                    <p>
                        {{ testTranslate(scope.row.test) }}
                    </p>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import queryString from "query-string";
export default {
    props: {
        clientsList: {
            type: Array,
            default: () => [],
        },
        clientStatus: {
            type: String,
            default: "Active",
        },
        isTest: {
            type: String,
            default: "false",
        },
    },

    methods: {
        handleCurrentChange(val) {
            this.$router.push({ path: "/clientDetails/" + val.clientId + "/general" });
        },

        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            query.clientStatus = this.clientStatus;
            query.isTest = this.isTest;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },

        testTranslate(item) {
            const translations = {
                0: "",
                1: "Testbolag",
            };
            return translations[item];
        },
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },
};
</script>
